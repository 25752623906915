<template>
  <!-- Üst Sağ - Kullanıcı Menu Alanı-->
  <div class="lb-nav-content-right">

    <NuxtLinkLocale v-if="status === 'unauthenticated'" href="/login" class="flex flex-col items-center space-y-1">
      <UserIcon class="h-4 w-4 lg:m-0 mx-1.5" />
      <span class="text-sm lg:block hidden">{{ $t('navigation.my-account') }}</span>
    </NuxtLinkLocale>
    
    <template v-else-if="status === 'authenticated'">
      <UserDropdownMenu class="flex flex-col items-center space-y-1">
        <template #icon>
          <UserIcon class="h-4 w-4" />
        </template>
        {{ firstName }}
      </UserDropdownMenu>
    </template>

    <NuxtLinkLocale
        v-if="status === 'authenticated'"
        href="/account/wishlist"
        class="flex flex-col items-center space-y-1"
    >
      <HeartIcon class="h-4 w-4 lg:m-0 mx-2.5" />
      <span class="text-sm lg:block hidden">{{ $t('navigation.favorites') }}</span>
    </NuxtLinkLocale>

    <NuxtLinkLocale
        v-else-if="status === 'unauthenticated'"
        href="/login"
        class="flex-col items-center space-y-1 lg:flex hidden"
    >
      <HeartIcon class="h-4 w-4 lg:m-0 mx-1.5" />
      <span class="text-sm">{{ $t('navigation.favorites') }}</span>
    </NuxtLinkLocale>

    <!-- Sepet Bölümü-->
    <ShoppingCartMenu class="group order-6" isTopBasketCount="true">
      <div class="flex flex-col items-center space-y-1 pr-1.5">
        <ShoppingBag class="w-4 h-4 lg:m-0 mx-2.5" />
        <span class="text-sm lg:block hidden">{{ $t('navigation.basket') }}</span>
      </div>
    </ShoppingCartMenu>
  </div>
</template>

<script setup>
import ShoppingBag from "~/assets/icons/shopping-bag.svg";
import UserIcon from "~/assets/icons/user.svg";
import { HeartIcon } from "@heroicons/vue/24/outline";

const {status, data} = await useAuth()
const {isWholesaleUser} = await useCustomer()
const firstName = computed(() => (unref(data)?.user?.name).split(' ').slice(0, -1).join(' ') ? (unref(data)?.user?.name).split(' ').slice(0, -1).join(' ') : (unref(data)?.user?.name).split('@').slice(0, -1).join(' '))

</script>

<style lang="scss">
.lb-nav-content-right {
  @apply flex items-center justify-end gap-2 lg:gap-5 order-4 col-span-2;
}
</style>