<template>
  <component :is="FooterTemplate" />
</template>

<script setup>
const storeConfig = useStoreConfig()
const { themeComponents } = storeToRefs(storeConfig)

const FooterTemplate = shallowRef()
switch(unref(themeComponents)?.footer_component) {
  case '2':
    FooterTemplate.value = resolveComponent('TemplatesFooterLayout2')
    break;
  case '3':
    FooterTemplate.value = resolveComponent('TemplatesFooterLayout3')
    break;
  case '4':
    FooterTemplate.value = resolveComponent('TemplatesFooterLayout4')
    break;
  case '5':
    FooterTemplate.value = resolveComponent('TemplatesFooterLayout5')
    break;
  default :
    FooterTemplate.value = resolveComponent('TemplatesFooterLayout1')
}
</script>