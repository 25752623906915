<template>
  <component :is="TopMenuComponent" />
</template>

<script setup>
const storeConfig = useStoreConfig()
const { themeComponents } = storeToRefs(storeConfig)

const TopMenuComponent = shallowRef()
switch(unref(themeComponents)?.navigation_helper_component) {
  case 'Default':
    TopMenuComponent.value = resolveComponent('TemplatesNavigationTopHelperMenuDefault')
    break;
  case 'TopHelperMenu2':
    TopMenuComponent.value = resolveComponent('TemplatesNavigationTopHelperMenu2')
    break;
  case 'TopHelperMenu3':
    TopMenuComponent.value = resolveComponent('TemplatesNavigationTopHelperMenu3')
        break;
  case 'TopHelperMenu4':
    TopMenuComponent.value = resolveComponent('TemplatesNavigationTopHelperMenu4')
    break;
  default :
    TopMenuComponent.value = null
}
</script>