<template>
  <component :is="MenuTemplate" />
</template>

<script setup>

const storeConfig = useStoreConfig()
const { themeComponents } = storeToRefs(storeConfig)

const MenuTemplate = shallowRef();
switch(unref(themeComponents)?.navigation_menu_component) {
  case '2':
    MenuTemplate.value = resolveComponent('TemplatesNavigationMenu2')
    break;
  case '3':
    MenuTemplate.value = resolveComponent('TemplatesNavigationMenu3')
    break;
  default :
    MenuTemplate.value = resolveComponent('TemplatesNavigationMenu1')
}
</script>