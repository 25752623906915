<template>
  <PopoverGroup as="div" class="flex h-full justify-center space-x-8">
    <Popover v-slot="{ open, close }">
        <PopoverButton
            class="relative z-10 px-3 flex items-center text-base font-medium transition-colors duration-200 ease-out outline-none"
            v-if="!open" @click="isBackgroundColor = true"
        >
          <Bars2Icon class="h-5 w-5 mr-2" />
          Menu

        </PopoverButton>

        <PopoverButton
            class="relative z-10 px-3 flex items-center text-base font-medium transition-colors duration-200 ease-out outline-none"
            v-else @click="isBackgroundColor = false"
        >
          <XMarkIcon class="h-5 w-5 mr-2"/>
          Kapat
        </PopoverButton>

      <transition
          enter-active-class="transition ease-out duration-200"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition ease-in duration-150"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
      >
        <OnClickOutside @trigger="isBackgroundColor = false">
          <PopoverPanel
              as="div"
              class="absolute inset-x-0 top-full shadow-lg text-primary sm:text-sm backdrop-blur bg-gradient-to-b from-white to-gray-200/80"
          >
            <div class="relative flex justify-between">
              <div class="w-full px-10 py-6 max-w-screen">
                <div class="flex space-x-28">
                  <div
                      v-for="navigation in navigationMenu"
                      :key="navigation.id"
                  >
                    <h3 class="text-2xl font-bold uppercase">
                      {{ navigation.name[locale] }}
                    </h3>
                    <ul role="list" class="mt-5 space-y-5">
                      <li
                          class="flow-root text-xl"
                          v-for="item in navigation.children"
                          :key="item.id"
                          @click="close()"
                      >
                        <NuxtLinkLocale
                            :href="item.href[locale]"
                            class="flex gap-3 -m-2.5 items-center px-2.5 py-1 rounded-md hover:text-secondary-500 text-gray-900 transition ease-in-out duration-150"
                        >
                            <span
                                class="h-7 w-7 flex items-center justify-center"
                                aria-hidden="true"
                                v-if="item?.custom?.icon"
                                v-html="item?.custom?.icon"
                            ></span>
                          {{ item.name[locale] }}
                        </NuxtLinkLocale>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="sm:flex hidden justify-end">
                <template v-for="featured in featuredMenu">
                  <NuxtLinkLocale :href="featured.href[locale]">
                    <NuxtImg
                        :src="featured.custom.image"
                        loading="lazy"
                        fit="inside"
                        width="400"
                        height="400"
                        v-if="featured.custom?.image"
                    />
                    <span v-else>
                      {{ featured.name[locale] }}
                    </span>
                  </NuxtLinkLocale>

                </template>
              </div>
            </div>
          </PopoverPanel>
        </OnClickOutside>
      </transition>
    </Popover>
  </PopoverGroup>
</template>
<script setup>
import {
  PopoverGroup,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/vue";
import {Bars2Icon, XMarkIcon} from "@heroicons/vue/24/outline";
import {OnClickOutside} from "@vueuse/components";

const isBackgroundColor = useState("isBackgroundColor", () => false);

const {locale} = useI18n();
const props = defineProps(['close'])

const storeConfig = useStoreConfig()
const { navigationMenu, featuredMenu } = storeToRefs(storeConfig)

</script>
