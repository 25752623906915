<template>
  <RegionSelector class="lg:!hidden" v-if="isRegionControlIsActive && !isDesktop" />

  <LayoutNavigationTopHelperMenu />

  <div class="lb-nav" :class="[isStickyNavigation ? 'sticky top-0' : 'relative']">
    <component :is="NavbarTemplate"/>

    <Teleport to="body">
      <MobileMenu />
    </Teleport>
  </div>
</template>

<script setup>
const {isMobile, isTablet, isDesktop} = useDevice()
const NavbarTemplate = shallowRef();

const storeConfig = useStoreConfig()
const { themeComponents, isStickyNavigation, isRegionControlIsActive } = storeToRefs(storeConfig)

switch(unref(themeComponents)?.navigation_component) {
  case '2':
    NavbarTemplate.value = resolveComponent('TemplatesNavigationLayout2')
    break;
  case '3':
    NavbarTemplate.value = resolveComponent('TemplatesNavigationLayout3')
    break;
  case '4':
    NavbarTemplate.value = resolveComponent('TemplatesNavigationLayout4')
    break;
  case '5':
    NavbarTemplate.value = resolveComponent('TemplatesNavigationLayout5')
    break;
  case '6':
    NavbarTemplate.value = resolveComponent('TemplatesNavigationLayout6')
    break;
  case '7':
    NavbarTemplate.value = resolveComponent('TemplatesNavigationLayout7')
    break;
  default :
    NavbarTemplate.value = resolveComponent('TemplatesNavigationLayout1')
}

</script>