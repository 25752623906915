<template>
  <div class="footer-container">
    <!-- Newsletter -->
    <div class="footer-newsletter-content">
      <Newsletter class="w-full lg:w-auto lg:min-w-[350px]" />
    </div>

    <!-- Footer -->
    <div class="container footer-content" v-if="isShowFooter">
      <div class="footer-inner-wrapper">

        <!-- Newsletter -->
        <div class="">

          <Logo class="block mb-2"/>

          <div class="space-y-2 pt-2">
            <div class="space-y-2">
              <a class="block text-lg font-medium hover:text-secondary" :href="`tel:${phoneNumber}`" v-if="phoneNumber">
                <LucidePhone class="size-4 inline-block mr-1.5"/>
                {{ phoneNumber }}</a>
              <a class="block text-lg font-medium hover:text-secondary" :href="`tel:${phoneNumber2}`" v-if="phoneNumber2">
                <LucidePhoneCall class="size-4 inline-block mr-1.5"/>
                {{ phoneNumber2 }}
              </a>
              <a class="block text-lg font-medium hover:text-secondary" :href="`mail:${supportEmail}`" v-if="supportEmail">
                <LucideAtSign class="size-4 inline-block mr-1.5"/>
                {{ supportEmail }}
              </a>
            </div>

            <div class="" v-if="contactConfig?.workingHoursIsActive || contactConfig?.workingHoursIsActive">
              <div class="font-medium">{{ $t('footer.working-hours') }}</div>
              <div v-if="contactConfig?.workingHoursIsActive">{{ workingDays }}</div>
              <div v-if="contactConfig?.workingHoursIsActive">{{ workingHours }}</div>
            </div>

            <p class="text-xs font-medium" v-if="address">{{ address }}</p>

            <!-- Social -->
            <template v-if="footerConfig?.social_media_links && Array.isArray(footerConfig.social_media_links)">
              <div class="flex flex-wrap gap-x-1.5 md:gap-x-5 pt-2">
                <a
                    :key="`nwm-${idx}`"
                    :href="getLocaleString(item.link)"
                    class="block"
                    v-for="(item, idx) in footerConfig.social_media_links"
                >
                  <NuxtImg class="size-5 object-contain" :src="item.icon" :alt="getLocaleString(item.name)" />
                </a>
              </div>
            </template>

          </div>
        </div>

        <template v-if="Array.isArray(footerMenu) && footerMenu.length > 0">
          <div class="sm:block hidden" v-for="i in footerMenu" :key="`ftm-${i.id}`">
            <ul role="list" class="text-left">
              <h3 class="text-base font-bold text-primary-400 mb-2 text-left tracking-wider uppercase" v-if="getLocaleString(i.name)">
                {{ getLocaleString(i.name) }}
              </h3>

              <li v-for="item in i.children" :key="`ftmc-${item.id}`">
                <NuxtLinkLocale
                  :to="getLocaleString(item.href, true)"
                  class="text-base text-primary-400 hover:underline"
                >
                  {{ getLocaleString(item.name) }}
                </NuxtLinkLocale>
              </li>
            </ul>
          </div>
          <div class="sm:hidden block">
            <TemplatesFooterMobileFooter/>
          </div>
        </template>

      </div>
    </div>

    <div class="container footer-html-area" v-if="getLocaleString(footerConfig?.legal_notice_text)" v-html="getLocaleString(footerConfig?.legal_notice_text)"></div>

    <!-- Copyright -->
    <Copyright />
  </div>
</template>

<script setup>
import {AtSymbolIcon} from "@heroicons/vue/20/solid/index.js";

const { isWholesaleUser } = await useCustomer();
const { isShowFooter } = useHelper();

const storeConfig = useStoreConfig()
const { footerMenu, footerConfig, contactConfig, etbis } = storeToRefs(storeConfig)

const phoneNumber = computed(() => contactConfig.value?.phone ? getLocaleString(contactConfig.value?.phone) : "")
const phoneNumber2 = computed(() => contactConfig.value?.phone_2 ? getLocaleString(contactConfig.value?.phone_2) : "")
const supportEmail = computed(() => contactConfig.value?.email ? getLocaleString(contactConfig.value?.email) : "")
const workingDays = computed(() => contactConfig.value.workingDays)
const workingHours = computed(() => contactConfig.value.workingHours)
const address = computed(() => contactConfig.value.address.tr)

</script>

<style lang="scss">
.footer-container {
  @apply flex flex-col text-primary border-none;
}

.footer-content {
  @apply pt-6 sm:pt-12 pb-2.5 px-4;
}

.footer-html-area {
  @apply text-sm py-4 text-primary;
}

.footer-inner-wrapper {
  @apply sm:grid sm:grid-cols-4 sm:space-x-10 sm:grid-flow-col auto-cols-max space-y-0 sm:space-y-10 md:space-y-0 max-w-screen-2xl mx-auto;
}
</style>
